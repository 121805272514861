import React from 'react';
import { graphql } from 'gatsby';
import About from '../components/About/About';
import SEO from '../components/SEO';
import Layout from '../components/Layout/Layout';
import Hero from '../components/Hero/Hero';

const oNama = ({ data }) => {
  const heroImage = data.heroImage.edges[0].node.childImageSharp.fluid;

  return (
    <Layout>
      <SEO title="O nama - Monolit" />

      <Hero image={heroImage} text="O nama" breadcrumbs={true} />
      <div className="container">
        <About type="full" title="Monolit - visinski radovi" />
      </div>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11022.971456498322!2d16.3267334!3d46.314944!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xddeaf6b1af4d3d5e!2sMONOLIT%20d.o.o.!5e0!3m2!1sen!2shr!4v1575999314048!5m2!1sen!2shr"
        width={`100%`}
        height={450}
        frameBorder={0}
        allowFullScreen=""
        title="Monolit d.o.o."
      ></iframe>
    </Layout>
  );
};

export const query = graphql`
  query {
    heroImage: allFile(filter: { relativeDirectory: { eq: "hero/about" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default oNama;
